import React, { useEffect, useState } from 'react';
import './styles.css';
import ubctime from '../../images/ubctime.png';
import caseClosed from '../../images/case-closed.png';
import camchify from '../../images/camchify.png';
import rite from '../../images/rite.png';
import hybridpe from '../../images/hybridpe.png';
import courzChekr from '../../images/courzChekr.png';
import ubcoac from '../../images/ubcoac.png';
import phoenix from '../../images/phoenix.png';
import intuit from '../../images/intuit.png';
import bethtchess from '../../images/bethtchess.png';
import arrowUp from '../../images/arrow-up.svg';
import linkedIn from '../../images/linkedin.svg';
import github from '../../images/github.svg';
import avatar from '../../images/avatarsvg.svg';
// import resume from '../../documents/arjay_resume.pdf';
import { animateScroll as scroll, Events, Link } from 'react-scroll'
import Typewriter from 'typewriter-effect';

// let title = "arja¥ :)";
// const titles = [
//     "arja¥ :)",
//     "hi 🖤",
//     "suh dud.",
//     "¯\\_(ツ)_/¯",
//     "( ͡° ͜ʖ ͡°)",
//     "ᕦ(ツ)ᕤ",
//     "ಠ_ಠ",
//     "[̲̅$̲̅(ツ)$̲̅]",
//     "💚",
// ];
function App() {
    // const [time, setTime] = useState(null);
    // useEffect(() => {
    //     // title = titles[Math.floor(Math.random() * titles.length)];
    //     const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    //     let secTimer = setInterval(() => {
    //         const d = new Date();
    //         // setTime(prepending(d.getHours()) + " : " + prepending(d.getMinutes()) + " : " + prepending(d.getSeconds()));
    //         setTime(d.toLocaleTimeString("en-US", {timeZone: tz, hour12: false}));
    //     })
    //     return () => clearInterval(secTimer);
    // }, []);
    const [scrollStyle, setScrollStyle] = useState({});
    const [loading, setLoading] = useState(true);
    //const loading = true;
    // // const [hamburgerActive, setHamburgerActive] = useState(false);
    // setLoading(true);
    useEffect(() => {
        const handleScroll = () => {
            if(window.scrollY > 500) {
                setScrollStyle({transform: 'scale(1)', height: '70%', visibility: 'visible'});
            } else if(window.scrollY !== 0) {
                setScrollStyle({transform: 'scale(0)', height: '70%', visibility: 'hidden'})
            }
        }

        window.addEventListener('scroll', handleScroll);

        Events.scrollEvent.register('begin', function() {
            console.log("begin", arguments);
        });

        Events.scrollEvent.register('end', function() {
            console.log("end", arguments);
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        }
    }, [scrollStyle]);

    const toggleClass = () => {
        const navbarLinks = document.getElementsByClassName('navbar-links')[0];
        navbarLinks.classList.toggle('active');
        // setHamburgerActive(navbarLinks.classList[1] !== undefined);
    }

    return (
        loading ? <p className="mid">
            {/*<span>😀 😃 😄 😁 😆 😅 😂 🤣 🥲 🥹 ☺️ 😊 😇 🙂 🙃 😉 😌 😍 🥰 😘 😗 😙 😚 😋 😛 😝 😜 🤪 🤨 🧐 🤓 😎 🥸 🤩 🥳 😏 😒 😞 😔 😟 😕 🙁 ☹️ 😣 😖 😫 😩 🥺 😢 😭 😮‍💨 😤 😠 😡 🤬 🤯 😳 🥵 🥶 😱 😨 😰 😥 😓 🫣 🤗 🫡 🤔 🫢 🤭 🤫 🤥 😶 😶‍🌫️ 😐 😑 😬 🫠 🙄 😯 😦 😧 😮 😲 🥱 😴 🤤 😪 😵 😵‍💫 🫥 🤐 🥴 🤢 🤮 🤧 😷 🤒 🤕 🤑 🤠 😈 👿 👹 👺 🤡 💩 👻 💀 ☠️ 👽 👾 🤖 🎃 😺 😸 😹 😻 😼 😽 🙀 😿 😾👋 🤚 🖐 ✋ 🖖 👌 🤌 🤏 ✌️ 🤞 🤟 🤘 🤙  👈 👉 👆 🖕 👇 ☝️ 👍 👎 ✊ 👊 🤛 🤜 👏 🫶 🙌 👐 🤲 🤝 🙏 ✍️ 💅 🤳 💪 🦾 🦵 🦿 🦶 👣 👂 🦻 👃 🫀 🫁 🧠 🦷 🦴 👀 👁 👅 👄 🫦 💋 🩸&nbsp;</span>*/}
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter
                            .pauseFor(1000)
                            .typeString('hi stranger,')
                            .pauseFor(500)
                            .typeString(' how are you? :)')
                            .pauseFor(1000)
                            .callFunction(() => {
                            setLoading(false)})
                            .start();
                    }}
                />
        </p> :
            <div className='home'>
                <header className="header" role="banner" id="top">
                    <div className="row nav-container">
                        <nav className="nav" role="navigation">
                            <a href="/#" className='nav-toggle-button' onClick={toggleClass}>
                                <span className="bar "/>
                                <span className="bar"/>
                                <span className="bar"/>
                            </a>
                            <div className="navbar-links">
                                <ul className="nav__items">
                                    <li className="nav__item"><Link activeClass="active" className="nav__link"
                                                                    to="education" spy={true} smooth={true}
                                                                    duration={500}>Education</Link></li>
                                    <li className="nav__item"><Link activeClass="active" className="nav__link"
                                                                    to="projects"
                                                                    spy={true} smooth={true}
                                                                    duration={500}>Projects</Link>
                                    </li>
                                    <li className="nav__item"><Link activeClass="active" className="nav__link"
                                                                    to="skills"
                                                                    spy={true} smooth={true}
                                                                    duration={500}>Skills</Link>
                                    </li>
                                    <li className="nav__item"><Link activeClass="active" className="nav__link"
                                                                    to="about"
                                                                    spy={true} smooth={true} duration={500}>About</Link>
                                    </li>
                                    <li className="nav__item"><Link activeClass="active" className="nav__link"
                                                                    to="contact"
                                                                    spy={true} smooth={true}
                                                                    duration={500}>Contact</Link>
                                    </li>
                                    <span/>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div className="header__text-box row">
                        <div className="header__text">
                            <h1 className="heading-primary">
                                <span>Arjay Andal</span>
                            </h1>
                            <p>Software Engineer</p>
                            <div className="resume-container">
                                {/*<a target="_blank" rel="noreferrer" href="mailto:rj.andal.cs@gmail.co"*/}
                                {/*   className="btn">rj.andal.cs@gmail.com</a>*/}
                                <a rel="noreferrer" href="mailto:rj.andal.cs@gmail.com" target="_blank" className="btn btn--pink">Hire me</a>
                                <a className="social-link" rel="noreferrer"
                                   href="https://www.linkedin.com/in/arjay02/"
                                   target="_blank"
                                   style={{marginLeft: '2rem'}}>
                                    <img src={linkedIn} title="Linkedin Profile"
                                         className="footer__social-image" alt="Linkedin"/>
                                </a>
                                <a className="social-link" rel="noreferrer" href="https://github.com/rjworks/"
                                   title="GitHub Profile"
                                   style={{
                                       marginLeft: '1rem'
                                   }}
                                   target="_blank">
                                    <img src={github} className="footer__social-image" alt="Github"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
                <main role="main">
                    <section className="client" id="education">
                        <div className="row">
                            <h2>Education</h2>
                            <div className="education">
                                <div className="dated-section">
                                    <h4>University of British Columbia, Kelowna BC</h4>
                                    <span>Sep 2020 - Apr 2025</span>
                                </div>
                                    <span><i>Bachelor of Science, Major in Computer Science</i></span>
                                </div>
                            <span>-</span>
                                <div className="education">
                                    <div className="dated-section">
                                        <h4>Sophia University, Shinjuku Tokyo</h4>
                                        <span>Mar 2024 - Aug 2024</span>
                                    </div>
                                    <span><i>Semester Exchange Program, Focus in Economics</i></span>
                                </div>
                            </div>
                    </section>

                    <section className="work" id="experience">
                        <div className="row">
                            <h2>Experience</h2>
                            <div className="work__boxes">
                                <div className="work__box">
                                    <div className="work__text">
                                        <div className='work_title'>
                                            <h1>Intuit</h1>
                                            <i>Software Engineer Co-op</i>
                                        </div>
                                        <ul className='work__list project-description'>
                                            <li>Constructed a web tool that replaced the manual JSON file editing workflow using JavaScript and React, doubling product setup productivity while adhering to company’s coding standards</li>
                                            <li>Programmed unit tests using Jest and Cypress, achieving 80-85% test coverage, enhancing code quality</li>
                                            <li>Assembled a Slack bot using Node.js, Slack API, and AWS Lambda to provide automated solutions for over 100
deprecated modules, boosting plugin maintainers productivity</li>
                                            <li>Implemented One-Time Password phone verification using JavaScript and React, reducing bot-based registrations
by 90–95% and enhancing platform security</li>
    <li>Incorporated Terms and Conditions into Sign-Up form to inform users about data usage, improving user trust</li>
    <li>Worked closely with cross-functional teams in an agile manner, participating in 100% of daily scrums</li>
                                        </ul>
                                    </div><b></b>
                                    <div className="work__image-box">
                                        <img src={intuit} width='50%' height='50%' className="work__image"
                                             alt="Intuit"/>
                                    </div>
                                </div>

                                <div className="work__box">
                                    <div className="work__text">
                                        <div className='work_title'>
                                            <h1>The Phoenix News</h1>
                                            <i>Web Developer</i>
                                        </div>
                                        {/*<h3>Web Developer @ The Phoenix News</h3>*/}

                                        <ul className='work__list project-description'>
                                            <li>Transformed and optimized the existing website with a new design using Webflow which resulted in a 100% increase in device responsiveness and user interactivity</li>
                                            <li>Collaborated effectively with cross disciplinary teams to design and publish articles, driving significant site traffic
                                                with 2,000+ clicks per article</li>
                                            <li>
                                                Coordinated with cross-functional teams to publish articles requiring intricate graphic and editorial collaboration. Each article averaged 2,000 clicks in under a week
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="work__image-box">
                                        <img src={phoenix} width='50%' height='50%' className="work__image"
                                             alt="The Phoenix News"/>
                                    </div>
                                </div>


                                <div className="work__box">
                                    <div className="work__text">
                                        <div className='work_title'>
                                            <h1>UBC Okanagan Astronomy Club</h1>
                                            <i>Software Developer</i>
                                        </div>
                                        <ul className='work__list project-description'>
                                            <li>Showed initiatives by becoming the primary developer for one of the
                                                fastest growing clubs at UBC Okanagan
                                            </li>
                                            <li>Learned how to build a website using WordPress that informs club members
                                                about future events and to gain more
                                                recognition from students
                                            </li>
                                            <li>
                                                Constructed a program that sends daily forecasts of astronomical
                                                observing conditions to the club members to help
                                                with the planning of stargazing nights
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="work__image-box">
                                        <img src={ubcoac} width='50%' height='50%' className="work__image"
                                             alt="UBC Okanagan Astronomy Club"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="work" id="projects">
                        <div className="row">
                            <h2>Personal Projects</h2>
                            <div className="work__boxes">
                                <div className="work__box">
                                    <div className="work__text">
                                        <h1>Course Availability Checker (courzChekr)</h1>
                                        <ul className='work__list project-description'>
                                            <li>Structured a Node.js program that notifies its users via a Discord
                                                server when a spot opens up for the courses they
                                                wish to enroll in. Hosted on an AWS EC2 instance for low cost (free
                                                tier) and reliable service

                                            </li>
                                            <li>Used Cheerio to web scrape UBC’s website</li>
                                            <li>Increased productivity by 10x by automating routine tasks to help UBC
                                                students save time
                                            </li>
                                        </ul>
                                        <div className='project-tech'>
                                            <div className='backend'>
                                                <span>Backend</span>
                                                <ul className='work__list'>
                                                    <li>JavaScript</li>
                                                    <li>Node.js</li>
                                                    <li>Discord.js</li>
                                                    <li>Cheerio</li>
                                                    <li>Axios</li>
                                                    <li>REST API</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="work__links">
                                            <a rel="noreferrer" href="https://discord.gg/ncUy9R3JWQ" target="_blank"
                                               className="link__text">
                                                Demo <span>&rarr;</span>
                                            </a>
                                            <a rel="noreferrer" href="https://github.com/rjworks/courzChekr"
                                               title="GitHub repo."
                                               target="_blank">
                                                <img src={github} className="work__code" alt="GitHub"/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="work__image-box">
                                        <img src={courzChekr} className="work__image" alt="courzChekr"/>
                                    </div>
                                </div>
                                 <div className="work__box">
                                    <div className="work__text">
                                        <h1>Minecraft Multiplayer Game Server (HybridPE)</h1>
                                        <ul className='work__list project-description'>
                                            <li>
                                                Led development of an online Minecraft gaming platform using Java, attracting 70,000+ users and leveraging
                                                MySQL and AWS S3 to store and manage big data efficiently
                                            </li>
                                            <li>
                                                Partnered with social media influencers, driving 100,000+ YouTube views and boosting platform engagement
                                            </li>
                                            <li>
                                                Designed and deployed an e-commerce site, facilitating $50,000+ CAD in transactions
                                            </li>
                                            <li>
                                                Deployed the platform on an AWS EC2 instance, providing cost-effective and reliable service with 99.9% uptime
                                            </li>
                                        </ul>
                                        <div className='project-tech'>
                                            <div className='backend'>
                                                <span>Backend</span>
                                                <ul className='work__list'>
                                                    <li>Java</li>
                                                    <li>PHP</li>
                                                    <li>PocketMine-API</li>
                                                    <li>AWS EC2, S3</li>
                                                    <li>Tebex</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                     <div className="work__image-box">
                                         <img src={hybridpe} className="work__image" alt="HybridPocket"/>
                                     </div>
                                 </div>
                                <div className="work__box">
                                    <div className="work__text">
                                        <h1>Receipt Tracker (Rite - NewHacks 2021)</h1>
                                        <ul className='work__list project-description'>
                                            <li>Built and deployed a React application that scans receipts in real
                                                time and summarizes them to help people keep a record of their
                                                daily, weekly, and monthly spending sorted by categories. Completed
                                                within 24 hours
                                            </li>
                                            <li>Implemented OpenCV to process receipts in real time through the
                                                user’s camera
                                            </li>
                                        </ul>
                                        <div className='project-tech'>
                                            <div className='frontend'>
                                                <span>Technologies used</span>
                                                <ul className='work__list'>
                                                    <li>OpenCV</li>
                                                    <li>React</li>
                                                    <li>TailwindCSS</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="work__links">
                                            <a rel="noreferrer" href="https://rite.abyx.dev/" target="_blank"
                                               className="link__text">
                                                Demo <span>&rarr;</span>
                                            </a>
                                            <a rel="noreferrer" href="https://github.com/Kenny2github/Rite"
                                               target="_blank">
                                                <img src={github} className="work__code"
                                                     title="View Source Code" alt="GitHub"/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="work__image-box">
                                        <img src={rite} className="work__image" alt="Camchify"/>
                                    </div>
                                </div>

                                    <div className="work__box">
                                        <div className="work__text">
                                            <h1>UBC Time</h1>
                                            <ul className='work__list project-description'>
                                                <li>Engineered and deployed a <b>full-stack</b> web application that
                                                    allows users to browse my school’s courses and create worklists with
                                                    ease
                                                </li>
                                                <li>Utilized <b>React</b> for the frontend and used <b>Redux</b> to
                                                    manage global states for seamless dynamic updates
                                                </li>
                                                <li>Architected a Node.js backend server and designed a <b>REST
                                                    API</b> to scrape large amounts of data from my school’s website and
                                                    store them in a <b>MongoDB</b> database
                                                </li>
                                            </ul>
                                            <div className='project-tech'>
                                                <div className='frontend'>
                                                    <span>Frontend</span>
                                                    <ul className='work__list'>
                                                        <li>React</li>
                                                        <li>Redux</li>
                                                        <li>Axios</li>
                                                        <li>Bootstrap</li>
                                                        <li>JavaScript</li>
                                                        <li>HTML</li>
                                                        <li>CSS</li>
                                                    </ul>
                                                </div>

                                                <div className='backend'>
                                                    <span>Backend</span>
                                                    <ul className='work__list'>
                                                        <li>Node.js</li>
                                                        <li>Express.js</li>
                                                        <li>MongoDB</li>
                                                        <li>Cheerio</li>
                                                        <li>JavaScript</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="work__links">
                                                <a rel="noreferrer" href="https://ubctime.com/" target="_blank"
                                                   className="link__text">
                                                    Demo <span>&rarr;</span>
                                                </a>
                                                <a rel="noreferrer" href="https://github.com/rjworks/ubctime-api"
                                                   title="GitHub API repo. It is closed source."
                                                   target="_blank">
                                                    <img src={github} className="work__code" alt="GitHub"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="work__image-box">
                                            <img src={ubctime} className="work__image" alt="UBCTime"/>
                                        </div>
                                    </div>

                                <div className="work__box">
                                    <div className="work__text">
                                        <h1>Case Closed</h1>
                                        <ul className='work__list project-description'>
                                            <li>
                                                Constructed and published a full-stack issue tracker application
                                                where users can create and manage issues
                                            </li>
                                            <li>
                                                Used React for the frontend and integrated <b>Google
                                                Login</b> using <b>Auth0</b> for fast and easy authentications
                                            </li>
                                            <li>Architected a backend server using Node.js and designed a REST API
                                                for managing data which are later stored in
                                                a MongoDB database
                                            </li>
                                        </ul>
                                        <div className='project-tech'>
                                            <div className='frontend'>
                                                <span>Frontend</span>
                                                <ul className='work__list'>
                                                    <li>React</li>
                                                    <li>React Router</li>
                                                    <li>Auth0</li>
                                                    <li>Axios</li>
                                                    <li>JavaScript</li>
                                                    <li>Material UI</li>
                                                    <li>HTML</li>
                                                    <li>CSS</li>
                                                </ul>
                                            </div>

                                            <div className='backend'>
                                                <span>Backend</span>
                                                <ul className='work__list'>
                                                    <li>JavaScript</li>
                                                    <li>Node.js</li>
                                                    <li>Express.js</li>
                                                    <li>MongoDB</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="work__links">
                                            <a rel="noreferrer" href="https://arjay.dev/case-closed" target="_blank"
                                               className="link__text">
                                                Demo <span>&rarr;</span>
                                            </a>
                                            <a rel="noreferrer" href="https://github.com/rjworks/case-closed"
                                               target="_blank">
                                                <img src={github} className="work__code"
                                                     title="View Source Code" alt="GitHub"/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="work__image-box">
                                        <img src={caseClosed} className="work__image"
                                             alt="Case Closed"/>
                                    </div>
                                </div>

                                <div className="work__box">
                                    <div className="work__text">
                                        <h1>Bethtchess (nwHacks 2021)</h1>
                                        <ul className='work__list project-description'>
                                            <li>During a 24-hour hackathon, my team and I built BethtChess, an app
                                                that gets the next best chess move in real time from your camera
                                            </li>
                                            <li>Developed a Node.js backend server for predicting the next best
                                                chess move
                                                </li>
                                                <li>Designed and built the frontend using React and <b>Fomatic-UI</b>.
                                                    Constantly communicated with my teammates
                                                </li>
                                            </ul>
                                            <div className='project-tech'>
                                                <div className='frontend'>
                                                    <span>Frontend</span>
                                                    <ul className='work__list'>
                                                        <li>JavaScript</li>
                                                        <li>React</li>
                                                        <li>Fomantic-UI</li>
                                                    </ul>
                                                </div>
                                                <div className='backend'>
                                                    <span>Backend</span>
                                                    <ul className='work__list'>
                                                        <li>Express.js</li>
                                                        <li>Node.js</li>
                                                        <li>JavaScript</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="work__links">
                                                <a rel="noreferrer" href="https://github.com/rjworks/bethtchess"
                                                   target="_blank">
                                                    <img src={github} className="work__code"
                                                         title="View Source Code" alt="GitHub"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="work__image-box">
                                            <img src={bethtchess} className="work__image" alt="Camchify"/>
                                        </div>
                                    </div>

                                    <div className="work__box">
                                        <div className="work__text">
                                            <h1>Case Closed</h1>
                                            <ul className='work__list project-description'>
                                                <li>
                                                    Constructed and published a full-stack issue tracker application
                                                    where users can create and manage issues
                                                </li>
                                                <li>
                                                    Used React for the frontend and integrated <b>Google
                                                    Login</b> using <b>Auth0</b> for fast and easy authentications
                                                </li>
                                                <li>Architected a backend server using Node.js and designed a REST API
                                                    for managing data which are later stored in
                                                    a MongoDB database
                                                </li>
                                            </ul>
                                            <div className='project-tech'>
                                                <div className='frontend'>
                                                    <span>Frontend</span>
                                                    <ul className='work__list'>
                                                        <li>React</li>
                                                        <li>React Router</li>
                                                        <li>Auth0</li>
                                                        <li>Axios</li>
                                                        <li>JavaScript</li>
                                                        <li>Material UI</li>
                                                        <li>HTML</li>
                                                        <li>CSS</li>
                                                    </ul>
                                                </div>

                                                <div className='backend'>
                                                    <span>Backend</span>
                                                    <ul className='work__list'>
                                                        <li>JavaScript</li>
                                                        <li>Node.js</li>
                                                        <li>Express.js</li>
                                                        <li>MongoDB</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="work__links">
                                                <a rel="noreferrer" href="https://arjay.dev/case-closed" target="_blank"
                                                   className="link__text">
                                                    Demo <span>&rarr;</span>
                                                </a>
                                                <a rel="noreferrer" href="https://github.com/rjworks/case-closed"
                                                   target="_blank">
                                                    <img src={github} className="work__code"
                                                         title="View Source Code" alt="GitHub"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="work__image-box">
                                            <img src={caseClosed} className="work__image"
                                                 alt="Case Closed"/>
                                        </div>
                                    </div>

                                    <div className="work__box">
                                        <div className="work__text">
                                            <h1>Camchify</h1>
                                            <ul className='work__list project-description'>
                                                <li>
                                                    Programmed a fully functional and responsive <b>E-commerce</b> React
                                                    application
                                                </li>
                                                <li>Implemented <b>Commerce.js</b> as a headless commerce backend</li>
                                                <li>Utilized <b>Stripe’s</b> API to make real payments</li>
                                            </ul>
                                            <div className='project-tech'>
                                                <div className='frontend'>
                                                    <span>Frontend</span>
                                                    <ul className='work__list'>
                                                        <li>JavaScript</li>
                                                        <li>React</li>
                                                        <li>Commerce.js</li>
                                                        <li>Stripe.js</li>
                                                        <li>Material UI</li>
                                                        <li>HTML</li>
                                                        <li>CSS</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="work__links">
                                                <a rel="noreferrer" href="https://arjay.dev/camchify" target="_blank"
                                                   className="link__text">
                                                    Demo <span>&rarr;</span>
                                                </a>
                                                <a rel="noreferrer" href="https://github.com/rjworks/Camchify"
                                                   target="_blank">
                                                    <img src={github} className="work__code"
                                                         title="View Source Code" alt="GitHub"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="work__image-box">
                                            <img src={camchify} className="work__image" alt="Camchify"/>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </section>

                    <section className="client" id="skills">
                        <div className="row">
                            <h2>Technical Skills</h2>
                            <div className="education">
                                <div className='project-tech'>
                                    <div className='languages'>
                                        <span>Languages</span>
                                        <ul className='work__list'>
                                            <li>Java</li>
                                            <li>JavaScript</li>
                                            <li>TypeScript</li>
                                            <li>PHP</li>
                                            <li>Python</li>
                                            <li>SQL</li>
                                            <li>HTML5</li>
                                            <li>CSS</li>
                                        </ul>
                                    </div>

                                    <div className='frontend'>
                                        <span>Frontend</span>
                                        <ul className='work__list'>
                                            <li>React</li>
                                            <li>Next.js</li>
                                            <li>Redux</li>
                                            <li>Axios</li>
                                            <li>DevOps</li>
                                            <li>Bootstrap</li>
                                            <li>Material UI</li>
                                        </ul>
                                    </div>

                                    <div className='backend'>
                                        <span>Backend</span>
                                        <ul className='work__list'>
                                            <li>Node.js</li>
                                            <li>Express.js</li>
                                            <li>MongoDB</li>
                                            <li>MySQL</li>
                                            <li>SQLite</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="about" id="about">
                        <div className="row">
                            <h2>About Me</h2>
                            <div className="about__content">
                                <div className="about__text">
                                    <p>

                                    I’m a third-year Computer Science major at The University of British Columbia and I consider myself a results-driven individual who’s passionate about topics in Computer Science. I have experience in full-stack web app development and I enjoy working in a team environment where I can receive feedback that will help me grow as a person and as a software engineer. Since my first year of college, I’ve been extremely passionate about learning new technologies and along the way, I have picked up a variety of practical skills that allow me to build applications using modern technologies like JavaScript, React, and Node.js just to name a few. Apart from coding, I enjoy spending my free time exercising, playing video games, and hiking. I am looking for internships to gain professional work experience and utilize my skills.

                                    </p>
                                    {/*<a href={resume} target='_blank' rel='noreferrer' className="btn">My Resume</a>*/}
                                    {/*<a rel="noreferrer" href={resume} target="_blank" className="btn btn--pink">My*/}
                                    {/*    resume</a>*/}
                                </div>

                                <div className="about__photo-container">
                                    <img className="about__photo" src={avatar} alt=""/>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <section className="contact" id="contact">
                    <div className="row">
                        <h2>Contact Me</h2>
                        <div className="contact__info">
                            <p>
                                You can contact me via email below and I will try to respond as soon as I can. Thanks!
                            </p>
                            <a target="_blank" rel="noreferrer" href="mailto:rj.andal.cs@gmail.com"
                               className="btn">rj.andal.cs@gmail.com</a>
                        </div>
                    </div>
                </section>

                <footer role="contentinfo" className="footer">
                    <div className="row">
                        <ul className="footer__social-links">
                            <li className="footer__social-link-item">
                                <a className="social-link" rel="noreferrer"
                                   href="https://www.linkedin.com/in/arjayandal/"
                                   target="_blank"
                                   style={{marginLeft: '2rem'}}>
                                    <img src={linkedIn} title="Linkedin Profile"
                                         className="footer__social-image" alt="Linkedin"/>
                                </a>
                            </li>
                            <li className="footer__social-link-item">
                                <a className="social-link" href="https://github.com/rjworks/" rel="noreferrer"
                                   title="GitHub Profile"
                                   style={{marginLeft: '1rem'}}
                                   target="_blank">
                                    <img src={github} className="footer__social-image" alt="Github"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </footer>
                {scrollStyle.visibility === 'visible' ?
                    <a href='/#' onClick={() => scroll.scrollToTop()} className={"back-to-top"}
                       style={{transform: 'scale(1)'}}
                       title="Back to Top">
                        <img src={arrowUp} alt="Back to Top" className='back-to-top__image'/>
                    </a>
                    :
                    <a href='/#' onClick={() => scroll.scrollToTop()} className={"back-to-top"}
                       style={{transform: 'scale(0)'}}
                       title="Back to Top">
                        <img src={arrowUp} alt="Back to Top" className='back-to-top__image'/>
                    </a>}
            </div>
    );
}

export default App;
