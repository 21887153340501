const bgFontColors = {
    "#FFFFFF": "black",
    "#F038FF": "white",
    "#FFEE56": "black",
    "#54FF9F": "black",
    "#427AFF": "white",
}
const bgColors = [
    "#FFFFFF",
    "#F038FF",
    "#FFEE56",
    "#54FF9F",
    "#427AFF",
]

export {bgColors, bgFontColors}