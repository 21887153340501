import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './components/home/Home';
import Quotchy from './components/quotchy/Quotchy';
import chem111tb from './documents/chem111tb.pdf';
import ptable from './documents/ptable.pdf';
import Valentine from './components/vday/Valentine';

function App() {
    const originalTitleRef = useRef('Arjay Andal');
    const [title, setTitle] = useState(originalTitleRef.current);

    useEffect(() => {
        const onFocus = () => {
            console.log("back");
            setTitle(originalTitleRef.current);
        };

        const onBlur = () => {
            console.log("left");
            setTitle("Come back 'ere!");
        };

        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);

        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="Arjay Andal's personal site"/>
                <meta property="og:title" content={title}/>
                <meta property="og:description" content="Explore Arjay Andal's projects and work."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://arjay.site/"/>
            </Helmet>
            <Router>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/linkedin"
                           render={() => window.location = "https://www.linkedin.com/in/arjayandal/"}/>
                    <Route exact path="/github" render={() => window.location = "https://github.com/rjworks/"}/>
                    <Route exact path="/case-closed" render={() => window.location = "https://case-closed.netlify.app/"} />
                    <Route exact path="/camchify" render={() => window.location = "https://camchify.netlify.app/"} />
                    <Route path="/quotchy" component={Quotchy} />
                    <Route path="/vday" component={Valentine} />
                    <Route exact path="/chem111tb" render={() => window.open(chem111tb, "_self")} />
                    <Route exact path="/ptable" render={() => window.open(ptable, '_self')} />
                    <Route>
                        <div>Page not found</div>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
